import {
  addDays,
  addHours,
  addWeeks,
  differenceInDays,
  format,
  formatDistance,
  formatRelative,
  getDay,
} from 'date-fns';
import { FillDay } from '@og-shared/types';
import {
  getNow,
  getTodayString,
  exhaustiveCheck,
  formatDate,
  getDateAtMidnight,
  getNextFillDate,
} from '@og-shared/utils';

import { translate } from './translate';

export type DateFormatOption = 'YYYY-MM-DD' | 'MM/DD/YYYY' | 'M/D/YY';

export function formatDateFromCSV(
  dateString: string,
  format: DateFormatOption
) {
  if (!dateString) return undefined;
  switch (format) {
    case 'MM/DD/YYYY': {
      const dateArray = dateString.split('/');
      const month = dateArray[0];
      const day = dateArray[1];
      const year = dateArray[2];
      if (!year) return '';
      return `${year ?? ''}-${addLeadingZeros(month)}-${addLeadingZeros(day)}`;
    }
    case 'M/D/YY': {
      const dateArray = dateString.split('/');
      const month = dateArray[0];
      const day = dateArray[1];
      const year = dateArray[2];
      if (!year) return '';
      return `20${year}-${addLeadingZeros(month)}-${addLeadingZeros(day)}`;
    }
    case 'YYYY-MM-DD': {
      const dateArray = dateString.split('-');
      const year = dateArray[0];
      const month = dateArray[1];
      const day = dateArray[2];
      if (!day) return '';
      return `${year}-${addLeadingZeros(month)}-${addLeadingZeros(day)}`;
    }
    default: {
      return exhaustiveCheck(format);
    }
  }
}

export function getDateFormatFromCSV(
  data: { [key: string]: string }[],
  key: string
): DateFormatOption {
  const transactionWithDate = data.find(d => d[key] > '');
  if (!transactionWithDate) return 'MM/DD/YYYY';
  if (transactionWithDate[key].includes('/')) {
    if (transactionWithDate[key].length < 10) {
      return 'M/D/YY';
    }
    return 'MM/DD/YYYY';
  }
  return 'YYYY-MM-DD';
}

function addLeadingZeros(numberString: string) {
  if (!numberString) return '';
  if (numberString.length === 1) return `0${numberString}`;
  return numberString;
}

export function getTimeUntilFillDate(fillDay: FillDay) {
  const startDate = getTodayString();
  const fillDate = getNextFillDate(startDate, fillDay);
  return ogFormatDistanceFromNow(fillDate);
}

export function calcDateGoalAchieved(
  fillDay: FillDay,
  goalBalance: number,
  goalAmount: number,
  weeklyFill: number
) {
  const startDate = getTodayString();
  if (weeklyFill == 0) {
    return null;
  }
  const goalNeeded = goalAmount - goalBalance;
  const fillsNeeded = Math.ceil(goalNeeded / weeklyFill);
  const nextFillDate = formatDate(getNextFillDate(startDate, fillDay));
  const goalAchievedDate = addWeeks(
    getDateAtMidnight(nextFillDate),
    fillsNeeded - 1
  );
  const goalAchievedString = formatDate(goalAchievedDate);
  return goalAchievedString;
}

export function isFillDay(fillDay: number = 0, date: string) {
  const dayOfWeek = getDay(getDateAtMidnight(date));
  return dayOfWeek === fillDay;
}

export function ogFormatDistanceFromNow(date: Date) {
  if (typeof date !== 'object') return '';
  const now = getNow();
  return formatDistance(date, now, { addSuffix: true });
}

export function ogFormatRelativeToday(date: Date, includeTime?: boolean) {
  if (typeof date !== 'object') return '';
  const now = getNow();
  const daysSinceDate = differenceInDays(now, date);
  if (daysSinceDate > 6 || daysSinceDate < -6) {
    return format(date, 'M/d/yy');
  }
  const formatted = formatRelative(date, now);
  const removeLast = formatted.replace('last ', '');
  if (includeTime) {
    return removeLast;
  }
  const removeTime = removeLast.replace(' at 12:00 AM', '');
  return `${removeTime} ${format(date, 'M/d/yy')}`;
}

export function getNextDateString(dateString: string) {
  const date = getDateAtMidnight(dateString);
  const offset = new Date(date).getTimezoneOffset() * 60 * 1000;
  const localTimestamp = new Date(date).getTime() - offset;
  const newDateString = formatDate(addDays(localTimestamp, 1));
  if (newDateString === dateString) {
    // fixes weird issue on daylight savings where we get stuck on one date
    return formatDate(addHours(addDays(localTimestamp, 1), 12));
  }
  return newDateString;
}

export function getReadableDateRange(start_date: string, end_date: string) {
  if (!start_date) return null;
  const startString = format(getDateAtMidnight(start_date), 'M/d/yyyy');
  const endString = end_date
    ? format(getDateAtMidnight(end_date), 'M/d/yyyy')
    : translate('TODAY');
  return `${startString} - ${endString}`;
}
